import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';

const Contact = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/02cb14ad-067d-405f-9d30-8cc581871cfc",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks for connecting, we will be in touch soon!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
    <Layout>
    <Helmet   
    htmlAttributes={{lang: 'en',}}>
      <title lang="en">Contact On The Block Real Estate International</title>
      <meta name="description" content="Contact On The Block Real Estate International brokered by eXp.
      Our Team is available 24/7 send us a message through our chat, or connect with us through phone." lang="en"/>
    </Helmet>
  
    <section className="pt-20 md:pt-40 ContactBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 text-center">
                Your Agent & Broker On The Block
            </h1>
        </div>
    </section>
  
    <section className="pt-5">
      <div className="container mx-auto">
        <h2 className="text-3xl mx-5 mb-5 font-semibold text-center">Connect With Us Today</h2>
        <div className="container mx-auto py-5 bg-gray-200 rounded-lg">
            <div className="col-md-8 mt-5 row">
                <h3 className="font-semibold text-l mx-5 mb-5">Please complete the form below:</h3>
                <form className="grid grid-flow-row auto-rows-max md:auto-rows-min" onSubmit={handleOnSubmit}>
                    <input className="mx-5 my-2 pl-2 rounded h-8 placeholder-gray-800" type="email" name="email" placeholder="Your Email"/>
                    <input className="mx-5 my-2 pl-2 rounded h-8 placeholder-gray-800" type="text" name="name" placeholder="Your Name"/>
                    <br/>
                    <p className="mx-5 my-2 rounded"> Please Select Real Estate Need</p>
                    <select className="mx-5 py-1 rounded pl-2" name="static-site-generator">
                        <option value="buying">Buying</option>
                        <option value="selling">Selling</option>
                        <option value="agent">Real Estate Agent</option>
                        <option value="general">General Question</option>
                    </select>
                    <input className="mx-5 rounded my-5 h-8 pl-2 placeholder-gray-800" type="text" name="message" placeholder="Your Message"/>
                    <button className="mx-5"><Button type="submit">Send</Button></button>
                </form>
            </div>
        </div>
      </div>
    </section>
    </Layout>
  );
};

export default Contact;